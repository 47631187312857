import React from "react";
import * as utils from '../../../_metronic/_helpers/LocalStorageHelpers'
import { DashboardFilters } from "./DashboardFilters";
import * as dashboardCrud from './DashboardCrud';
import { useEffect } from "react";
import moment from 'moment';
import { shallowEqual, useSelector } from "react-redux";
import { useState } from "react";
import { TopProductsWidget } from "./Widgets/TopProductsWidget";
import { MapWidget } from "./Widgets/MapWidget";
import { TopCustomersWidget } from "./Widgets/TopCustomersWidget";


const saveFilters= last_filter=>{
    utils.setStorage(
      'FilterDashboardOrders',
      JSON.stringify(last_filter)
    )
  }

const initialFilter={
    dateFrom:moment().startOf('month').toDate(),
    dateTo:new Date(),
    businessUnit:null,
    iso_id:undefined
}
export function DashboardPage() {

    const [filter, setFilter] = useState(initialFilter);
    const [entities, setEntities] = useState({});
    const [provincia, setProvincia] = useState({});
    const [loading, setLoading] = useState(false);
    const { business_units } = useSelector(
        (state) => ({ business_units: state.helpers.BusinessUnits }),
        shallowEqual
      );

    const loadData = async()=>{
        
        setLoading(true);
        const params={
            business_unit_id:filter.businessUnit.value,
            order_creation_from:moment(filter.dateFrom).format("YYYYMMDD"),
            order_creation_to:moment(filter.dateTo).format("YYYYMMDD"),
            iso_id:filter.iso_id
        }
        saveFilters(params);

        try{
            let resp = await dashboardCrud.getSales({filter:params})
            const sales = resp.data.results;
            
            resp = await dashboardCrud.getRanking({filter:params})
            const ranking = resp.data.results;
    
            resp = await dashboardCrud.getRankingCustomers({filter:params})
            const ranking_customers = resp.data.results;
    
            if(sales || ranking){
                const data=resp.data.results;
                setEntities({...entities,
                    total: sales.total || null,
                    total_tax:sales.total_tax || null,
                    shipping_total:sales.shipping_total || null,
                    count:sales.count || null,
                    topProducts:ranking || [],
                    topCustomers:ranking_customers || []
                })
            } 
            setLoading(false);

        }
        catch(err){
            setLoading(false);

        }      

    }

    useEffect(()=>{
        if(filter.businessUnit){
            loadData();
        }
    }, [filter])

    useEffect(()=>{

        if(filter.businessUnit && filter.iso_id!=provincia.iso_id){
            setFilter({...filter, iso_id:provincia.iso_id})
        }
    }, [provincia])


    useEffect(()=>{
        let _memVars = JSON.parse(utils.getStorage("FilterDashboardOrders"));
        if(_memVars){
            setFilter({
                businessUnit:business_units.filter(b=>b.value==_memVars.business_unit_id)[0],
                dateFrom:moment(_memVars.order_creation_from).toDate(),
                dateTo:moment(_memVars.order_creation_to).toDate()
            })
        }
        else{
            setFilter({...filter, businessUnit:business_units.filter(b=>b.label.toUpperCase()=='SHOPDROFAR')[0]})
        }
        
    }, [])

  return (
    <>
        <div className="row">
            <div className="col-lg-12 col-xxl-12">
                <DashboardFilters className="card-stretch gutter-b" filter={filter} setFilter={setFilter}/>
            </div>
        </div>

        {/* <div className="row">
            <div className="col-lg-4">
                <VentasWidget className="gutter-b" baseColor="success" widgetHeight="150px" entities={entities}/>
            </div>
            <div className="col-lg-4">
                <VentasEnviosWidget className="gutter-b" baseColor="primary" widgetHeight="150px" entities={entities}/>
            </div>
            <div className="col-lg-4 col-xxl-4 order-1 order-xxl-1">
                <VentasPromWidget className="card-stretch gutter-b" baseColor="warning" widgetHeight="150px" entities={entities} />
            </div>         
        </div> */}


        <div className="row">
            <MapWidget className="card-stretch gutter-b"   setProvincia={setProvincia}  entities={entities} provincia={provincia} loading={loading}/>
        </div>
        
        <div className="row">
            <div className="col-lg-6 ">
                <TopProductsWidget className="card-stretch gutter-b"  entities={entities}/>
            </div>
            <div className="col-lg-6 ">
                <TopCustomersWidget className="card-stretch gutter-b"  entities={entities}/>
            </div>
        </div>

        {/* <div className="row">
            <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
            <ListsWidget3 className="card-stretch gutter-b" />
            </div>
            <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
            <ListsWidget4 className="card-stretch gutter-b" />
            </div>
            <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
            <TilesWidget12 className="card-stretch gutter-b" />
            </div>
        </div> */}
    </>
  );
}
