/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {separadorMiles} from "../../Global/helpers";
import moment from 'moment'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import '../styles.css'

export function KPIClientesNuevosWidget({
  className,
  baseColor = "primary",
  widgetHeight = "150px",
  filter,
  entities,
  loading
}) {
    
    const [porcentual, setPorcentual] = useState(0);
    const [baseColorState, setBaseColor] = useState('white');

    useEffect(()=>{
        if(entities && entities.q_clientes_nuevos_principal){
            setBaseColor("white")
        }
    }, [entities])
    

  return (
    <>
        <div className={`card card-custom bg-${baseColorState} ${className}`} style={{ minHeight: widgetHeight }} >
            <div className="card-body" style={{padding:"10px"}}>
                <div className="text-left">
                    <span className="svg-icon svg-icon-3x svg-icon-primary ml-n2">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")} />
                        {loading  && <i className="fas fa-2x fa-spinner fa-spin text-primary"></i> }
                    </span>

                    <table className="w-100">
                        <tbody>
                            <tr>
                                <td className={`text-inverse-${baseColorState} font-weight-bolder font-size-h1`} style={{width:"70%"}}>{entities?.q_clientes_nuevos_principal}</td>
                                <td></td>
                            </tr>
                            <tr style={{height:"80px"}}>
                                <td colSpan={2}>
                                    <span className={`text-inverse-${baseColorState} font-weight-bold text-muted font-size-h3 mt-1`} >
                                        Clientes Creados 
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                            <Tooltip id="tooltip-top">
                                                Creados en el período { filter?.principal_desde? moment(filter.principal_desde, 'YYYYMMDD').format('DD/MM/YYYY') : '' } { filter?.principal_hasta? ' - ' + moment(filter.principal_hasta, 'YYYYMMDD').format('DD/MM/YYYY') : '' }
                                            </Tooltip>
                                            }
                                        >
                                            <i className="ml-5 text-default fa fa-info-circle" />
                                        </OverlayTrigger>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                   
                </div>
            </div>
        </div>
    </>
  );
}
