import React, { useEffect, useRef, useState } from "react";
import { Calendar, DateRangePicker, DefinedRange } from "react-date-range";
import { shallowEqual, useSelector } from "react-redux";
import Select from 'react-select';
import { addDays } from "date-fns";
import 'react-date-range/dist/styles.css'; // Estilos por defecto
import 'react-date-range/dist/theme/default.css'; // Tema por defecto
import { es } from 'react-date-range/src/locale';
import './styles.css'
import { rangos } from "./rangos";
import moment from 'moment';
import * as dashboardCrud from './DashboardCrud'
import * as XLSX from 'xlsx/xlsx.mjs';

export function DashboardAbbottPrincipalFilters({className, filter, setFilter, optionsProducts, setLoading}) {
    const [fechaRecurrencia, setFechaRecurrencia] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    
    const [dateRangePrincipal, setDateRangePrincipal] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection1',
        }
    ]);
    const [dateRangeComparacion, setDateRangeComparacion] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection2',
        }
    ]);
    const [showCalendarPrincipal, setShowCalendarPrincipal] = useState(false);
    const [showCalendarComparacion, setShowCalendarComparacion] = useState(false);
    const calendarPrincipalRef = useRef(null);
    const calendarComparacionRef = useRef(null);
    
    const handleOutsideClick = (event) => {
        if (calendarPrincipalRef.current && !calendarPrincipalRef.current.contains(event.target)) {
            setShowCalendarPrincipal(false);
        }
        if (calendarComparacionRef.current && !calendarComparacionRef.current.contains(event.target)) {
            setShowCalendarComparacion(false);
        }
    };

    const handleSelectPrincipal = (ranges) => {
        setDateRangePrincipal([ranges.selection1]);
    };
    
    const handleSelectComparacion = (ranges) => {
        setDateRangeComparacion([ranges.selection2]);
    };

    const toggleCalendarPrincipal = () => {
        if(!showCalendarPrincipal)
            setShowCalendarPrincipal(!showCalendarPrincipal); 
    };
    const toggleCalendarComparacion = () => {
        if(!showCalendarComparacion)
            setShowCalendarComparacion(!showCalendarComparacion);
    };
    const { business_units } = useSelector(
        (state) => ({ business_units: state.helpers.BusinessUnits }),
        shallowEqual
    );


    const handleActual = ()=>{
        let inicioMesActual = moment().startOf('month');
        // Fin del mes actual
        let finMesActual = moment()
        // Inicio del mes anterior
        let inicioMesAnterior = moment().subtract(1, 'months').startOf('month');
        // Fin del mes anterior
        let finMesAnterior = moment().subtract(1, 'months').endOf('month');

        setDateRangePrincipal(prevState => [{
              ...prevState[0], 
              startDate: inicioMesActual.toDate(),
              endDate:finMesActual.toDate()
            }
          ]);
        setDateRangeComparacion(prevState => [{
            ...prevState[0], 
            startDate: inicioMesAnterior.toDate(),
            endDate:finMesAnterior.toDate()
          }
        ]);
    }

    const handleCompleto = ()=>{
        // Determina si el mes actual está completo (es decir, si el día actual es el último día del mes)
        let esMesActualCompleto = moment().endOf('month').isSame(moment(), 'day');
        // Inicio y fin del "mes completo" más reciente
        let inicioMesCompleto = esMesActualCompleto ? moment().startOf('month') : moment().subtract(1, 'months').startOf('month');
        let finMesCompleto = esMesActualCompleto ? moment().endOf('month') : moment().subtract(1, 'months').endOf('month');
        // Inicio y fin del mes anterior al "mes completo" más reciente
        let inicioMesCompletoAnterior = inicioMesCompleto.clone().subtract(1, 'months').startOf('month');
        let finMesCompletoAnterior = inicioMesCompleto.clone().subtract(1, 'months').endOf('month');
        setDateRangePrincipal(prevState => [{
              ...prevState[0], 
              startDate: inicioMesCompleto.toDate(),
              endDate:finMesCompleto.toDate()
            }
          ]);
        setDateRangeComparacion(prevState => [{
            ...prevState[0], 
            startDate: inicioMesCompletoAnterior.toDate(),
            endDate:finMesCompletoAnterior.toDate()
          }
        ]);
    }

    const handleAnioAnterior = ()=>{
        // Determina si el mes actual está completo (es decir, si el día actual es el último día del mes)
        let esMesActualCompleto = moment().endOf('month').isSame(moment(), 'day');

        // Inicio y fin del "mes completo" más reciente
        let inicioMesCompleto = esMesActualCompleto ? moment().startOf('month') : moment().subtract(1, 'months').startOf('month');
        let finMesCompleto = esMesActualCompleto ? moment().endOf('month') : moment().subtract(1, 'months').endOf('month');

        // Inicio y fin del mismo mes pero del año anterior
        let inicioMesMismoAnioAnterior = inicioMesCompleto.clone().subtract(1, 'years');
        let finMesMismoAnioAnterior = finMesCompleto.clone().subtract(1, 'years');
        setDateRangePrincipal(prevState => [{
              ...prevState[0], 
              startDate: inicioMesCompleto.toDate(),
              endDate:finMesCompleto.toDate()
            }
          ]);
        setDateRangeComparacion(prevState => [{
            ...prevState[0], 
            startDate: inicioMesMismoAnioAnterior.toDate(),
            endDate:finMesMismoAnioAnterior.toDate()
          }
        ]);
    }

    const cerrarCalendarios=()=>{
        setShowCalendarPrincipal(false)
        setShowCalendarComparacion(false)
    }
    
    const exportSellout = async ()=>{
        try{
            const params={
                business_unit_id:filter.business_unit_id,
                product_id:selectedProducts.map(p=>p.value),
                principal_desde:filter.principal_desde,
                principal_hasta:filter.principal_hasta,
                comparacion_desde:filter.comparacion_desde,
                comparacion_hasta:filter.comparacion_hasta
            }
            if(!params.product_id){
                params.product_id = optionsProducts.map(p=>p.value)
            }
            let resp = await dashboardCrud.postSellout(params)
            if(resp?.data?.results){
                let datos = resp.data.results.map(d=>{
                    let data={
                      "Nº Transac": d.id,
                      "ID Cliente": d.customer_id,
                      "Direccion": d.address_street,
                      "Altura": d.address_street_number,
                      "Piso": d.address_floor,
                      "Departamento": d.address_apartment,
                      "Codigo postal": d.address_postal_code,
                      "Localidad": d.address_locality_name,
                      "Provincia": d.address_state_name,
                      "Pais": d.iso_id?.split('-')[0],
                      "Como conocio?": "",
                      "Fecha de nacimiento": "",
                      "Fecha de Compra": d.order_creation,
                      "Cantidad": d.order_line_qty,
                      "Producto": d.product_name,
                      "Precio de Venta": d.order_line_price,
                      "Tipo de Cobertura": d.health_insurance_type_label,
                      "Obra Social": d.health_insurance,
                      "Medico": d.doctor,
                      "Tipo de Diabetes": d.customer_dbt_type_desc
                    };
    
                    return data
                })
    
                var ws = XLSX.utils.json_to_sheet(datos);
    
                var wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "Ordenes");
                XLSX.writeFile(wb, `Redimer sell-out ${moment().format("MM.YYYY")}.xlsx` );
    
            }

            setLoading(false);
        }
        catch(err){
            setLoading(false);

        }  
    }

    const ejecutar = ()=>{

        setFilter({
            ...filter, 
            principal_desde : dateRangePrincipal[0].startDate ? moment(dateRangePrincipal[0].startDate).format("YYYYMMDD"):null, 
            principal_hasta : dateRangePrincipal[0].endDate ? moment(dateRangePrincipal[0].endDate).format("YYYYMMDD"):null, 
            comparacion_desde : dateRangeComparacion[0].startDate ? moment(dateRangeComparacion[0].startDate).format("YYYYMMDD"):null, 
            comparacion_hasta : dateRangeComparacion[0].endDate ? moment(dateRangeComparacion[0].endDate).format("YYYYMMDD"):null,
            product_id: selectedProducts?.length ? selectedProducts.map(p=>p.value) : null,
            business_unit_id : business_units.filter(b=>b.value==1).map(b=>b.value),
            fecha_recurrencia : fechaRecurrencia ? moment(fechaRecurrencia).format("YYYYMMDD"):null,
        })

    }


    useEffect(() => {
        
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };

        
    }, []);

    useEffect(()=>{
        if(filter){
            setDateRangePrincipal(prevState => {
                const newState = prevState.map(range => {
                    if (range.key === 'selection1') {
                        return {
                            ...range, 
                            startDate: filter.principal_desde ? moment(filter.principal_desde, "YYYYMMDD").toDate() : new Date(),
                            endDate: filter.principal_hasta ? moment(filter.principal_hasta, "YYYYMMDD").toDate() : new Date()
                        };
                    }
                    return range;
                });
                return newState;
            })
            setDateRangeComparacion(prevState => {
                const newState = prevState.map(range => {
                    if (range.key === 'selection2') {
                        return {
                            ...range, 
                            startDate: filter.comparacion_desde ? moment(filter.comparacion_desde, "YYYYMMDD").toDate() : new Date(),
                            endDate: filter.comparacion_hasta ? moment(filter.comparacion_hasta, "YYYYMMDD").toDate() : new Date()
                        };
                    }
                    return range;
                });
                return newState;
            })
        }
        
        setFechaRecurrencia(filter.fecha_recurrencia? moment(filter.fecha_recurrencia, "YYYYMMDD").toDate() : new Date())

    }, [filter])

    useEffect(()=>{
        setSelectedProducts(optionsProducts?.filter(item => filter?.product_id?.includes(item.value)))
    }, [optionsProducts])

    return (
        <>
            <div className={`card card-custom ${className}`}>
                <div className="card-body pt-2 pb-0">
                    <div className="row mt-5 pb-5">

                        <div className="col-3">
                            <div style={{ position: 'relative' }} >
                                <input
                                    type="text"
                                    className="form-control"
                                    value={`${dateRangePrincipal[0].startDate.toLocaleDateString('es-ES')} - ${dateRangePrincipal[0].endDate.toLocaleDateString('es-ES')}`}
                                    onClick={toggleCalendarPrincipal}
                                    readOnly
                                />
                                {showCalendarPrincipal && (
                                    <div style={{ position: 'absolute', top: 'calc(100% - 15px)', left: 0, zIndex:"100" }}  ref={calendarPrincipalRef}>
                                        <DateRangePicker
                                            ranges={dateRangePrincipal}
                                            onChange={handleSelectPrincipal}
                                            months={1}
                                            direction="horizontal"
                                            locale={es} 
                                            maxDate={new Date()}
                                            showDateDisplay={false}
                                            showPreview={false}
                                            inputRanges={[]}
                                            className="custom-wrapper"
                                            staticRanges={rangos}
                                            footerContent={<>
                                                <div className="mt-5 mb-5 text-center">
                                                    <button type="button" className="btn btn-primary" onClick={cerrarCalendarios}>Guardar</button>
                                                </div></>}                                           
                                            headerContent={
                                            <>
                                                <label className="form-label mt-5 pl-3"><b>Selección rápida:</b></label>
                                                <div className="d-block">
                                                    <button type="button" className="rdrStaticRange w-100" onClick={handleCompleto}>
                                                        <span className="rdrStaticRangeLabel">Mes completo actual/ant.</span>
                                                    </button>
                                                    <button type="button" className="rdrStaticRange w-100" onClick={handleAnioAnterior}>
                                                        <span className="rdrStaticRangeLabel">Año actual/ant.</span>
                                                    </button>
                                                </div>
                                                <label className="form-label mt-5 mb-0 pl-3"><b>Rangos:</b></label>
                                                
                                            </>
                                            }
                                            // headerContent={<><label className="form-label mt-2 mb-0 pl-3">Rangos:</label></>}                                           
                                            // footerContent={
                                            // <>
                                            //     <label className="form-label mt-5 pl-3">Selección rápida:</label>
                                            //     <div className="d-block">
                                            //         <button type="button" className="rdrStaticRange w-100" onClick={handleActual}>
                                            //             <span className="rdrStaticRangeLabel">Actual</span>
                                            //         </button>
                                            //         <button type="button" className="rdrStaticRange w-100" onClick={handleCompleto}>
                                            //             <span className="rdrStaticRangeLabel">Completo</span>
                                            //         </button>
                                            //         <button type="button" className="rdrStaticRange w-100" onClick={handleAnioAnterior}>
                                            //             <span className="rdrStaticRangeLabel">Año anterior</span>
                                            //         </button>
                                            //     </div>
                                            //     <div className="mt-5 mb-5 text-center">
                                            //         <button type="button" className="btn btn-primary" onClick={cerrarCalendarios}>Guardar</button>
                                            //     </div>
                                                
                                            // </>
                                            // }
                                        />
                                    </div>

                                )}
                                <small className="form-text text-muted">
                                Rango de fechas <b>principal</b>
                                </small>
                            </div>
                        </div> 
                        <div className="col-3">
                            <div style={{ position: 'relative' }} >
                                <input
                                    type="text"
                                    className="form-control"
                                    value={`${dateRangeComparacion[0].startDate.toLocaleDateString('es-ES')} - ${dateRangeComparacion[0].endDate.toLocaleDateString('es-ES')}`}
                                    onClick={toggleCalendarComparacion}
                                    readOnly
                                />
                                {showCalendarComparacion && (
                                    <div style={{ position: 'absolute', top: 'calc(100% - 15px)', left: 0, zIndex:"100" }}  ref={calendarComparacionRef}>
                                        <DateRangePicker
                                            ranges={dateRangeComparacion}
                                            onChange={handleSelectComparacion}
                                            months={1}
                                            direction="horizontal"
                                            locale={es} 
                                            maxDate={new Date()}
                                            showDateDisplay={false}
                                            showPreview={false}
                                            inputRanges={[]}
                                            staticRanges={rangos}
                                            className="custom-wrapper-comparacion"
                                            headerContent={<><label className="form-label mt-2 mb-0 pl-3">Rangos:</label></>}                                           
                                            footerContent={
                                            <>
                                                <div className="mt-5 mb-5 text-center">
                                                    <button type="button" className="btn btn-primary" onClick={cerrarCalendarios}>Guardar</button>
                                                </div>
                                            </>
                                            }
                                        />
                                    </div>
                                )}
                                <small className="form-text text-muted">
                                Rango de fechas <b>comparación</b>
                                </small>
                            </div>
                        </div> 
                        <div className="col-3">
                            <Select 
                                options={business_units} 
                                value = {business_units.filter(b=>b.value==1)}
                                // onChange={value=>{setFilter({...filter, businessUnit:value})}}
                                isDisabled={true}
                            />
                            <small className="form-text text-muted">
                            <b>Unidad de Negocio</b>
                            </small>
                        </div> 
                        <div></div>
                    </div>
                    <div className="row mt-5 mb-5">
                        <div className="col-6">
                            <Select 
                                options={optionsProducts} 
                                value = {selectedProducts}
                                onChange={value=>{setSelectedProducts(value)}}
                                isMulti="true"
                            />
                            <small className="form-text text-muted">
                            <b>Producto</b>
                            </small>
                        </div> 
                        <div className="col text-right">
                            <button type="button" onClick={exportSellout} className="btn btn-light-primary mr-3" > 
                                <i className="fas fa-download"></i>
                                Sellout
                            </button>   
                            <button type='button' className="btn btn-primary" 
                                disabled={showCalendarPrincipal || showCalendarComparacion}
                                onClick={ejecutar}><i className="fas fa-play"></i>Ejecutar
                            </button>
                        </div>
                    </div>
                    {/* <div className="row mt-4 mb-8">
                        <div className="col-9">
                        </div> 
                        <div className="col-3 text-right">
                            <button type='button' className="btn btn-primary" 
                                disabled={showCalendarPrincipal || showCalendarComparacion}
                                onClick={ejecutar}>Ejecutar</button>
                        </div>

                    </div>   */}

                </div>
            </div>
        </>
    )
}