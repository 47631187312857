import React, { useState } from "react";
import {Tabs, Tab} from 'react-bootstrap'
import './styles.css'
import { DashboardAbbottPrincipal } from "./DashboardAbbottPrincipal";
import { DashboardAbbottDetalle } from "./DashboardAbbottDetalle";
import { DashboardAbbottRecurrencia } from "./DashboardAbbottRecurrencia";

export function DashboardAbbottPage() {
    const [activeTab, setActiveTab] = useState('principal')

    const handleSelect = (eventKey) => {
        setActiveTab(eventKey);
    }

    return (
    <>
        <Tabs
            defaultActiveKey="principal"
            className="mb-3"
            onSelect={handleSelect}
        >
            <Tab eventKey="principal" title="Principal">
                <DashboardAbbottPrincipal activeTab={activeTab} />
            </Tab>
            <Tab eventKey="recurrencia" title="Recurrencia">
                <DashboardAbbottRecurrencia activeTab={activeTab} />
            </Tab>
            <Tab eventKey="detalle" title="Mapa">
                <DashboardAbbottDetalle activeTab={activeTab} />
            </Tab>
        </Tabs>

    </>
  );
}
