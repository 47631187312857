import React, { useState } from "react";
import * as utils from '../../../_metronic/_helpers/LocalStorageHelpers'
import * as dashboardCrud from './DashboardCrud';
import * as productsCrud from '../Inventory/_redux/productsCrud';
import { useEffect } from "react";
import moment from 'moment';
import { shallowEqual, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { AbbottRecurrenciaWidget } from "./Widgets/AbbottRecurrenciaWidget";
import { DashboardAbbottRecurrenciaFilters } from "./DashboardAbbottRecurrenciaFilters";

const initialFilter={
    product_id:null,
    business_unit_id:null,
    fecha_recurrencia:new Date()
}
const saveFilters= last_filter=>{
    utils.setStorage(
      'FilterAbbottRecurrencia',
      JSON.stringify(last_filter)
    )
  }

export function DashboardAbbottRecurrencia({activeTab}) {
    const [optionsProducts, setOptionsProducts] = useState([])
    const [filter, setFilter] = useState(initialFilter);
    const [entities, setEntities] = useState({});
    const [loading, setLoading] = useState(false);
    
    const { business_units } = useSelector(
        (state) => ({ business_units: state.helpers.BusinessUnits }),
        shallowEqual
      );
    
    const fetchProducts = async()=>{
        const resp = await productsCrud.getProductsAbbott()
        setOptionsProducts(resp.data.results.map(p=>({value:p.id, label:p.name})))
    }

    const loadData = async()=>{
        setLoading(true);
        const params={
            business_unit_id:filter.business_unit_id,
            product_id:filter.product_id,
            fecha_recurrencia:filter.fecha_recurrencia
        }
        saveFilters(params);

        if(!params.product_id){
            params.product_id = optionsProducts.map(p=>p.value)
        }

        try{
            let resp = await dashboardCrud.getDashboardRecurrencia(params)
            const recurrencia =  resp.data.results;
            const metadata =  resp.data.metadata;

// console.log({ ...recurrencia, metadata:metadata, hayDatos:true})
            if(recurrencia){
                setEntities({...recurrencia, metadata:metadata, hayDatos:true})
            } 
            else{
                setEntities({hayDatos:false})
            }
            setLoading(false);

        }
        catch(err){
            setLoading(false);

        }      

    }
    

    useEffect(()=>{
        if(filter.fecha_recurrencia)
            loadData();
    }, [filter])


    useEffect(()=>{
        
        let _memVars = JSON.parse(utils.getStorage("FilterAbbottRecurrencia"));
        if(_memVars){
            setFilter({
                product_id: _memVars.product_id,
                business_unit_id:business_units.filter(b=>b.value==_memVars.business_unit_id).map(v=>v.value),
                fecha_recurrencia:moment(_memVars.fecha_recurrencia)?.format("YYYYMMDD"),
            })
        }
        else{
            setFilter({...filter, businessUnit:business_units.filter(b=>b.label.toUpperCase()=='SHOPDROFAR')[0]})
        }
        
    }, [])

    useEffect(()=>{
        fetchProducts()
        if(activeTab=='recurrencia'){

        }
        
    }, [activeTab])



    return (
        <>
            <div className="row">
                <div className="col">
                    <DashboardAbbottRecurrenciaFilters className="card-stretch gutter-b" filter={filter} setFilter={setFilter} optionsProducts={optionsProducts} setLoading={setLoading}/>
                </div>
            </div>

            { loading 
                ?
                    <div className="row">
                        <div  className="d-flex justify-content-center align-items-center" style={{ width: '100vw', height:"100px"}}>
                            {/* <div  className="spinner spinner-primary"></div> */}
                            <CircularProgress className="splash-screen-spinner" color="primary" />
                        </div>                        
                    </div>
                :
                    ( entities?.hayDatos?
                        <>

                            <div className="row mt-4">
                                <div className="col">
                                    <h3>Recurrencia</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                <AbbottRecurrenciaWidget className="gutter-b"  widgetHeight="180px" entities={entities} filter={filter} />
                                </div> 

                            </div>

                        </>
                    :
                            <h3>No hay datos disponibles</h3>
                        )
                        
                    }

        </>
    )
}