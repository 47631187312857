import axios from "axios";

export const DASHBOARD_URL =`${process.env.REACT_APP_API_URL}/dashboard`;

// http://localhost:3000/api/dashboard/orders/sales?filter={"business_unit_id":1,"order_creation_from":"20220401","order_creation_to":"20220410"}


let cancelToken;

export function getSales(params){
    return (async () => {
        try {
            if (typeof cancelToken != typeof undefined) {
              cancelToken.cancel("Cancelado por new Request.")
            }
            cancelToken = axios.CancelToken.source();

            const resp = await axios.get(DASHBOARD_URL + '/orders/sales', { params, cancelToken: cancelToken.token });

            return resp;

        } catch (err) {
          
            if (axios.isCancel(err)) {
              //console.log('Request canceled');
            } else {
              // handle error
            }
            throw err;
        }
    })();
}
  
export function getSalesAbbott(params){
  return (async () => {
      try {
          if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Cancelado por new Request.")
          }
          cancelToken = axios.CancelToken.source();

          const resp = await axios.get(DASHBOARD_URL + '/orders/sales-abbott', { params, cancelToken: cancelToken.token });

          return resp;

      } catch (err) {
        
          if (axios.isCancel(err)) {
            //console.log('Request canceled');
          } else {
            // handle error
          }
          throw err;
      }
  })();
}
//http://localhost:3000/api/dashboard/orders/product/ranking/count?filter={"business_unit_id":1,"order_creation_from":"20220401","order_creation_to":"20220410"}
export function getRanking(params){
    return (async () => {
        try {
            if (typeof cancelToken != typeof undefined) {
              cancelToken.cancel("Cancelado por new Request.")
            }
            cancelToken = axios.CancelToken.source();

            const resp = await axios.get(DASHBOARD_URL + '/orders/product/ranking/count', { params, cancelToken: cancelToken.token });

            return resp;

        } catch (err) {
          
            if (axios.isCancel(err)) {
              //console.log('Request canceled');
            } else {
              // handle error
            }
            throw err;
        }
    })();
}

//http://localhost:3000/api/dashboard/orders/product/ranking/customer?filter={"iso_id":"AR-X","business_unit_id":1,"order_creation_from":"20220401","order_creation_to":"20220410"}
export function getRankingCustomers(params){
  return (async () => {
      try {
          if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Cancelado por new Request.")
          }
          cancelToken = axios.CancelToken.source();

          const resp = await axios.get(DASHBOARD_URL + '/orders/product/ranking/customer', { params, cancelToken: cancelToken.token });

          return resp;

      } catch (err) {
        
          if (axios.isCancel(err)) {
            //console.log('Request canceled');
          } else {
            // handle error
          }
          throw err;
      }
  })();
}

/*
POST http://localhost:3000/api/dashboard

{
  "principal_desde": "20220101",
  "principal_hasta": "20240131",
  "comparacion_desde": "20220201",
  "comparacion_hasta": "20240228",
  "product_id": [33, 32, 34, 99, 108, 116, 121, 122, 124, 126],
  "business_unit_id": [1]
}
*/
export function getDashboardPrincipal(params){
  return (async () => {
      try {

          const resp = await axios.post(DASHBOARD_URL + '/kpi/sales',  params );

          return resp;

      } catch (err) {
        
          throw err;
      }
  })();
}
export function getDashboardClientes(params){
  return (async () => {
      try {

          const resp = await axios.post(DASHBOARD_URL + '/kpi/customers',  params );

          return resp;

      } catch (err) {
        
          throw err;
      }
  })();
}

export function getDashboardRecurrencia(params){
  return (async () => {
      try {

          const resp = await axios.post(DASHBOARD_URL + '/kpi/customers/recurrence',  params );

          return resp;

      } catch (err) {
        
          throw err;
      }
  })();
}

export function getExcelRecurrencia(params){
  return (async () => {
      try {

          const resp = await axios.post(DASHBOARD_URL + '/kpi/customers/recurrence?add_emails=1',  params );

          return resp;

      } catch (err) {
        
          throw err;
      }
  })();
}

export function postSellout(params){
  return (async () => {
      try {

          const resp = await axios.post(DASHBOARD_URL + '/sellout-abbott',  params );

          return resp;

      } catch (err) {
        
          throw err;
      }
  })();
}

