import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./modules/Dashboard/DashboardPage";
import UsersPage from "./modules/Users/pages/UsersPage";
import { CustomersPage } from "./modules/Customers/pages/CustomersPage";
import ReportsPage from "./modules/Reports/pages/ReportsPage";
import PharmacyPage from "./modules/Pharmacy/pages/PharmacyPage";
import InventoryPage from "./modules/Inventory/pages/InventoryPage";
import { shallowEqual, useSelector } from "react-redux";
import { useEffect } from "react";
import { DashboardAbbottPage } from "./modules/Dashboard/DashboardAbbottPage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const SalesPage = lazy(() =>
  import("./modules/Sales/pages/SalesPage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const { usuario } = useSelector(
    ({ auth }) => ({
      usuario: auth.user ,
    }),
    shallowEqual
  );

  useEffect(()=>{

  }, [usuario])
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          (usuario && usuario.role && usuario.role.name!='INVENTARIO')?
            (
              usuario && usuario.role && usuario.role.name!='ABBOTT'?
                <Redirect exact from="/" to="/logistica" />
                :
                <Redirect exact from="/" to="/dashboardV2" />
            )
          :
            <Redirect exact from="/" to="/inventario" />
        }
        {/* <ContentRoute path="/dashboard" component={DashboardPage} /> */}
        <ContentRoute path="/usuarios" component={UsersPage} />
        <ContentRoute path="/reportes" component={ReportsPage} />
        <ContentRoute path="/clientes" component={CustomersPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/farmacia" component={PharmacyPage} />
        <Route path="/inventario" component={InventoryPage} />
        <Route path="/logistica" component={SalesPage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/dashboard" component={DashboardPage} />
        <Route path="/dashboardV2" component={DashboardAbbottPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
