import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Select from 'react-select';
import 'react-date-range/dist/styles.css'; // Estilos por defecto
import 'react-date-range/dist/theme/default.css'; // Tema por defecto
import './styles.css'
import moment from 'moment';
import ReactDatePicker from "react-datepicker";
import * as dashboardCrud from './DashboardCrud';
import * as XLSX from 'xlsx/xlsx.mjs';

export function DashboardAbbottRecurrenciaFilters({className, filter, setFilter, optionsProducts, setLoading}) {
    const [fechaRecurrencia, setFechaRecurrencia] = useState(new Date());
    const [selectedProducts, setSelectedProducts] = useState(null);
 
    const { business_units } = useSelector(
        (state) => ({ business_units: state.helpers.BusinessUnits }),
        shallowEqual
    );
    const { authState } = useSelector(
        (state) => ({ authState: state.auth }),
        shallowEqual
      );
    
    const { user } = authState;

    const exportar = async ()=>{
        try{
            let _product_id=[];

            setLoading(true)

            if(selectedProducts.length==0){
                _product_id = optionsProducts.map(p=>p.value)
            }
            else{
                _product_id = selectedProducts.map(p=>p.value)
            }

            setFilter({
                ...filter, 
                product_id: _product_id,
                business_unit_id : business_units.filter(b=>b.value==1).map(b=>b.value),
                fecha_recurrencia : fechaRecurrencia ? moment(fechaRecurrencia).format("YYYYMMDD"):null,
            })

            let resp = await dashboardCrud.getExcelRecurrencia({
                ...filter, 
                product_id: _product_id,
                business_unit_id : business_units.filter(b=>b.value==1).map(b=>b.value),
                fecha_recurrencia : fechaRecurrencia ? moment(fechaRecurrencia).format("YYYYMMDD"):null})
            
            if(resp?.data?.results){
                const {nuevos_emails, inactivos_emails, esporadicos_emails, perdidos_emails, recuperados_emails, recurrentes_emails} = resp.data.results;
                var wb = XLSX.utils.book_new();
    
                let datos = nuevos_emails.map(d=>{
                    let data={
                      "Email": d.email,
                      "Compras": d.cant_compras,
                    };
                    return data
                })
                let ws = XLSX.utils.json_to_sheet(datos);
                XLSX.utils.book_append_sheet(wb, ws, "Nuevos");
    
                datos = inactivos_emails.map(d=>{
                    let data={
                      "Email": d.email,
                      "Compras": d.cant_compras,
                    };
                    return data
                })
                ws = XLSX.utils.json_to_sheet(datos);
                XLSX.utils.book_append_sheet(wb, ws, "Inactivos");
    
                datos = esporadicos_emails.map(d=>{
                    let data={
                      "Email": d.email,
                      "Compras": d.cant_compras,
                    };
                    return data
                })
                ws = XLSX.utils.json_to_sheet(datos);
                XLSX.utils.book_append_sheet(wb, ws, "Esporadicos");
    
                datos = perdidos_emails.map(d=>{
                    let data={
                      "Email": d.email,
                      "Compras": d.cant_compras,
                    };
                    return data
                })
                ws = XLSX.utils.json_to_sheet(datos);
                XLSX.utils.book_append_sheet(wb, ws, "Perdidos");
    
                datos = recuperados_emails.map(d=>{
                    let data={
                      "Email": d.email,
                      "Compras": d.cant_compras,
                    };
                    return data
                })
                ws = XLSX.utils.json_to_sheet(datos);
                XLSX.utils.book_append_sheet(wb, ws, "Recuperados");
    
                datos = recurrentes_emails.map(d=>{
                    let data={
                      "Email": d.email,
                      "Compras": d.cant_compras,
                    };
                    return data
                })
                ws = XLSX.utils.json_to_sheet(datos);
                XLSX.utils.book_append_sheet(wb, ws, "Recurrentes");
    
    
                XLSX.writeFile(wb, `Recurrencia ${moment().format("MM.YYYY")}.xlsx` );
    
    
            }
            setLoading(false)
        }
        catch(err){
            setLoading(false)
            console.log(err)
        }
        
    }

    const ejecutar = ()=>{
        setFilter({
            ...filter, 
            product_id: selectedProducts?.length ? selectedProducts.map(p=>p.value) : null,
            business_unit_id : business_units.filter(b=>b.value==1).map(b=>b.value),
            fecha_recurrencia : fechaRecurrencia ? moment(fechaRecurrencia).format("YYYYMMDD"):null,
        })
    }

    useEffect(()=>{
        setFechaRecurrencia(filter.fecha_recurrencia? moment(filter.fecha_recurrencia, "YYYYMMDD").toDate() : new Date())
    }, [filter])

    useEffect(()=>{
        setSelectedProducts(optionsProducts?.filter(item => filter?.product_id?.includes(item.value)))
    }, [optionsProducts])

    return (
        <>
            <div className={`card card-custom ${className}`}>
                <div className="card-body pt-2 pb-0">
                    <div className="row mt-5 pb-5">

                        <div className="col-3">
                            <ReactDatePicker
                                autoComplete='off'
                                className="form-control"
                                style={{ width: "100%" }}
                                name="fechaRecurrencia"
                                selected={fechaRecurrencia}
                                maxDate={new Date()}
                                dateFormat="dd/MM/yyyy"
                                onChange={date=>{ setFechaRecurrencia(date) }}

                            />
                            <small className="form-text text-muted">
                            Fecha <b>recurrencia</b>
                            </small>
                        </div> 
                        <div className="col-3">
                            <Select 
                                options={business_units} 
                                value = {business_units.filter(b=>b.value==1)}
                                // onChange={value=>{setFilter({...filter, businessUnit:value})}}
                                isDisabled={true}
                            />
                            <small className="form-text text-muted">
                            <b>Unidad de Negocio</b>
                            </small>
                        </div> 
                        <div className="col-6">
                            <Select 
                                options={optionsProducts} 
                                value = {selectedProducts}
                                onChange={value=>{setSelectedProducts(value)}}
                                isMulti="true"
                            />
                            <small className="form-text text-muted">
                            <b>Producto</b>
                            </small>
                        </div> 
                    </div>
                    <div className="row mt-5 mb-5">
                        <div className="col text-right">
                            { user.role.name=='ABBOTT'?
                                <></>
                                :
                                <button type='button' className="btn btn-light-primary mr-3" onClick={exportar}><i className="fas fa-download"></i>Exportar</button>
                            }
                            <button type='button' className="btn btn-primary" onClick={ejecutar}><i className="fas fa-play"></i>Ejecutar</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}