
import React, { useMemo, useState } from "react";
import { Formik, useFormikContext } from "formik";
import { useReportsUIContext } from "../ReportsUIContext";
import Select from 'react-select';
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import * as reportsActions from '../../_redux/reportsActions'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { OrderReportToCSV } from "./OrderReportToCSV";
import { LOGISTIC_STATE } from "../../../Sales/pages/orders/OrdersUIHelpers";
import '../../styles.css'
import { Form } from "react-bootstrap";
import * as XLSX from 'xlsx/xlsx.mjs';
import * as reportsCrud from '../../_redux/reportsCrud';



const prepareFilter = (queryParams, values) => {
    const { zone_id, shipping_type, order_creation_from , order_creation_to, source_type , logistic_state_id, payment_type, business_unit_id, details } = values;
    
    const newQueryParams = { ...queryParams, filter:{...queryParams.filter} };

    if(zone_id && zone_id !== ""){
      newQueryParams.filter.zone_id = zone_id;
    }
    else{
      newQueryParams.filter.zone_id = undefined;
    }
    
    // Filter by shipping_type
    if(shipping_type && shipping_type !== ""){
      newQueryParams.filter.shipping_type_id = shipping_type;
    }
    else{      
      newQueryParams.filter.shipping_type_id = undefined;
    }

    if(order_creation_from  && order_creation_from  !== ""){
      newQueryParams.filter.order_creation_from  = moment(order_creation_from ).format("YYYYMMDD");
    }
    else{      
      newQueryParams.filter.order_creation_from  = undefined;
    }

    if(order_creation_to && order_creation_to !== ""){
      newQueryParams.filter.order_creation_to = moment(order_creation_to).format("YYYYMMDD");
    }
    else{      
      newQueryParams.filter.order_creation_to = undefined;
    }

    if(source_type && source_type !== ""){
      newQueryParams.filter.source_id = source_type;
    }
    else{      
      newQueryParams.filter.source_id = undefined;
    }    

    if(logistic_state_id && logistic_state_id !== ""){
      newQueryParams.filter.logistic_state_id = logistic_state_id;
    }
    else{
      newQueryParams.filter.logistic_state_id = undefined;
    }
    
    if(payment_type && payment_type !== ""){
      newQueryParams.filter.payment_type_id = payment_type;
    }
    else{      
      newQueryParams.filter.payment_type_id = undefined;
    }
    if(business_unit_id && business_unit_id !== ""){
      newQueryParams.filter.business_unit_id = business_unit_id;
    }
    else{
      newQueryParams.filter.business_unit_id = undefined;
    }
    if(details && details !== ""){
      newQueryParams.filter.details = details;
    }
    else{
      newQueryParams.filter.details = undefined;
    }  
    return newQueryParams;
};

export function OrderReportFilters({ listLoading }) {
  const [createdFrom, setCreatedFrom] = useState(null);
  const [createdTo, setCreatedTo] = useState(null);
  const [newQueryParams, setNewQueryParams] = useState(null);
  const [detailsProducts, setDetailsProducts] = useState(false);
  const [detailsArticle, setDetailsArticle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validateAbbott, setValidateAbbott] = useState(false);
  
  const reportsUIContext = useReportsUIContext();
  const reportsUIProps = useMemo(() => {
    return {
      setActualReportQueryParams: reportsUIContext.setActualReportQueryParams,
      actualReportQueryParams: reportsUIContext.actualReportQueryParams,
      setWithDetails:reportsUIContext.setWithDetails,
      withDetails:reportsUIContext.withDetails
    };
  }, [reportsUIContext]);

  const { entities, business_units } = useSelector(
      (state) => ({
        entities: state.reports.report.entities,
        business_units: state.helpers.BusinessUnits
      }),
      shallowEqual
  );
  const logistic_states = [];
  for(var key in LOGISTIC_STATE){
    //if(key!='NO_DISPATCH')
      logistic_states.push(LOGISTIC_STATE[key]); 
  }

  const applyFilter = (values) => {
    // const newQueryParams = prepareFilter(reportsUIProps.actualReportQueryParams, values);

    // if (!isEqual(newQueryParams, reportsUIProps.actualReportQueryParams)) {
    //   newQueryParams.pageNumber = 1;
    //   reportsUIProps.setActualReportQueryParams(newQueryParams);
    // }
    setNewQueryParams(prepareFilter(reportsUIProps.actualReportQueryParams, values))
  };

  const payment_types = [
    {value:1, label:'Online'},
    {value:2, label:'Efectivo'}
  ]

  const zonasUI = [
    {value:1, label:'CABA'},
    {value:2, label:'GBA'},
    {value:3, label:'Interior'},
    {value:7, label:'Tierra del Fuego'}
  ]
  
  const shipping_types = [
      {value:1, label:'Envío normal'},
      {value:2, label:'Envío express'},
      {value:3, label:'Retiro en Farmacia'},
      {value:4, label:'Punto Pickit'},
  ]

  const source_types = [
      {value:1, label:'Woocommerce'},
      {value:2, label:'MeLi'},
  ]


  const dispatch = useDispatch();

  const executeReport=()=>{

      if(newQueryParams){
        newQueryParams.pageNumber = 1;
        reportsUIProps.setActualReportQueryParams(newQueryParams);
        dispatch(reportsActions.fetchReportsByName("orders", newQueryParams));
      }
      else{
        dispatch(reportsActions.fetchReportsByName("orders", reportsUIProps.actualReportQueryParams));
      }
      reportsUIProps.setWithDetails(detailsProducts || detailsArticle);
  }

  const fetchAbbott = async(values)=>{
    try{
      const resp = await reportsCrud.getReportByName("orders", {
        filter:{
          order_creation_from: moment(values.order_creation_from).format("YYYYMMDD"),
          order_creation_to:  moment(values.order_creation_to).format("YYYYMMDD"),
          details: 1,
          abbott: 1
        },
        pageNumber:1
      })
      return resp?.data?.results;
    }
    catch(err){

    }

  }
  const handleExportAbbott = async (values)=>{
    try{
        setValidateAbbott(true);
        if(!values.order_creation_from || ! values.order_creation_to)
          return;
        if(!loading) {
            setLoading(true);
            let datos = await fetchAbbott(values);

            datos = datos.map(d=>{
                let data={
                  "Nº Transac": d.id,
                  "ID Cliente": d.customer_id,
                  "Direccion": d.address_street,
                  "Altura": d.address_street_number,
                  "Piso": d.address_floor,
                  "Departamento": d.address_apartment,
                  "Codigo postal": d.address_postal_code,
                  "Localidad": d.address_locality_name,
                  "Provincia": d.address_state_name,
                  "Pais": d.iso_id?.split('-')[0],
                  "Como conocio?": "",
                  "Fecha de nacimiento": "",
                  "Fecha de Compra": d.order_creation,
                  "Cantidad": d.order_line_qty,
                  "Producto": d.product_name,
                  "Precio de Venta": d.order_line_price,
                  "Tipo de Cobertura": d.health_insurance_type_label,
                  "Obra Social": d.health_insurance,
                  "Medico": d.doctor,
                  "Tipo de Diabetes": d.customer_dbt_type_desc
                };

                return data
            })

            var ws = XLSX.utils.json_to_sheet(datos);

            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Ordenes");
            XLSX.writeFile(wb, `Redimer sell-out ${moment().format("MM.YYYY")}.xlsx` );
        }
    }
    catch(err){

    }
    finally{
      setLoading(false);
    }
  }

  const handleExportReport = async (values)=>{
    try{
        const newQuery = {...reportsUIProps.actualReportQueryParams};
        newQuery.pageSize = null;

        if(!loading) {
            setLoading(true);
            const response = await reportsCrud.getReportByName("orders", newQuery);

            const datos = response.data.results.map(item => {
              let linea = {
                  id:item.id,
                  fechaPedido:moment(item.order_creation).format("DD/MM/YYYY HH:mm") ,
                  origen:item.source_name=='WOOCOMMERCE'?'Shop':item.source_name, 
                  origin_id:item.origin_id,
                  fecha:moment(item.order_creation).format("DD/MM/YYYY HH:mm"),
                  estado:item.logistic_state_name,
                  nombre_cliente:item.customer_first_name,
                  apellido_cliente:item.customer_last_name,
                  dni:item.billing_tax_number,
                  id_cliente_shop:item.customer_origin_id,
                  dbt:item.customer_dbt_type_desc,
                  documento:item.billing_tax_number,
                  email: item.billing_email,
                  teléfono:item.billing_phone,
                  domicilio:item.address_street + ' ' + item.address_street_number ,
                  piso:item.address_apartment + ' ' + item.address_floor ,
                  localidad:item.address_locality_name,
                  CP:item.address_postal_code,
                  provincia:item.address_state_name,
                  envio_solicitado:item.shipping_type_origin_name,
                  envio:item.shipping_type_name,
                  ultimo_transportista: item.last_shipp,
                  total:item.total,
                  impuestos:item.total_tax,
                  monto_envio:item.total_shipping?parseFloat(item.total_shipping)/1.21:'',
                  tipo_pago:item.payment_type_name,
                  cant_entregas:item.delivery_count,
                  fecha_entrega:item.delivered_date?moment(item.delivered_date).format("DD/MM/YYYY HH:mm"):null ,
                  comprobante:item.invoice
              }
              if(item.product_name){
                  linea = {...linea,
                      producto:item.product_name,
                      sku:item.product_sku.toString(),
                      cantidad_prod:item.order_line_qty,
                      precio_prod_sin_iva:item.order_line_price
                  }
              }

              if(item.stock_item_name){
                  linea = {...linea,
                      articulo: item.stock_item_name,
                      cantidad_articulo: item.product_item_qty,
                      cantidad_items_combo: item.combo_qty,
                      precio_articulo_sin_iva: item.combo_avg_price
                  }
              }
              return linea;
            });

            var ws = XLSX.utils.json_to_sheet(datos);

            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Ordenes");
            XLSX.writeFile(wb, `Ordenes ${moment().format("MM.YYYY")}.xlsx` );
        }
    }
    catch(err){

    }
    finally{
      setLoading(false);
    }
  }

  const stylesSelect = { 
    menuPortal: (base) => ({ ...base, zIndex: 9999}),
    control: (css) => ( 
        ({...css,  width: "100%", marginLeft:"2px"})),
        menu: ({ width, ...css }) => ({
        ...css,
        width: "max-content"       
        }),
        // Add padding to account for width of Indicators Container plus padding
        option: (css) => ({ ...css, width: "100%" }),
  
  }

  return (
    <>
      <Formik
        initialValues={{
          status: "", // values => All=""/Selling=0/Sold=1
          condition: "", // values => All=""/New=0/Used=1
          searchText: "",
          details:undefined
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">

            <div className="form-group row">
              
                <div className="col-lg-3">
                    <ReactDatePicker
                        autoComplete='off'
                        className={`form-control ${validateAbbott && !values.order_creation_from?'border-danger':''}`}
                        style={{ width: "100%" }}
                        name="createdFrom"
                        selected={createdFrom}
                        maxDate={createdTo?(createdTo>new Date()?new Date():createdTo):new Date()}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => {
                          setCreatedFrom(date);
                          setFieldValue("order_creation_from", date?date:null);
                          handleSubmit();
                        }}
                    />

                    <small className="form-text text-muted">
                      Pedidos <b>desde</b>
                    </small>
                </div>
              
                <div className="col-lg-3">
                    <ReactDatePicker
                        autoComplete='off'
                        className={`form-control ${validateAbbott && !values.order_creation_to?'border-danger':''}`}
                        style={{ width: "100%" }}
                        name="createdTo"
                        selected={createdTo}
                        minDate={createdFrom}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) =>  {
                            setCreatedTo(date);
                            setFieldValue("order_creation_to", date?date:null);
                            handleSubmit();
                        }}
                    />
                    <small className="form-text text-muted">
                      Pedidos <b>hasta</b>
                    </small>
                </div>

                <div className="col-lg-3">
                    <Select 
                        options={shipping_types} 
                        isMulti="true"
                        onChange={(e) => {
                            setFieldValue("shipping_type", e?e.map(item=>item.value).join(','):null);
                            handleSubmit();
                        }}
                    />
                    <small className="form-text text-muted">
                    <b>Tipo de Envío</b>
                    </small>
                </div>

                <div className="col-lg-3">
                    <Select 
                        options={source_types} 
                        isMulti="true"
                        onChange={(e) => {
                            setFieldValue("source_type", e?e.map(item=>item.value).join(','):null);
                            handleSubmit();
                        }}
                    />
                    <small className="form-text text-muted">
                    <b>Origen</b>
                    </small>
                </div>               


            </div>

            <div className="form-group row">
                <div className="col-lg-3">
                    <Select 
                        options={payment_types} 
                        isMulti="true"
                        style={stylesSelect}
                        onChange={(e) => {
                            setFieldValue("payment_type", e?e.map(item=>item.value).join(','):null);
                            handleSubmit();
                        }}
                    />
                    <small className="form-text text-muted">
                    <b>Tipo pago</b>
                    </small>
                </div>
                <div className="col-lg-3">
                    <Select 
                        options={logistic_states} 
                        isMulti="true"
                        style={stylesSelect}
                        onChange={(e) => {
                            setFieldValue("logistic_state_id", e?e.map(item=>item.value).join(','):null);//.replace('1', '1,6'):null);
                            handleSubmit();
                        }}
                    />
                    <small className="form-text text-muted">
                    <b>Estado</b>
                    </small>
                </div>
                <div className="col-lg-3">
                <Select 
                      options={zonasUI} 
                      isMulti="true"
                      style={stylesSelect}
                      onChange={(e) => {
                          setFieldValue("zone_id", e?e.map(item=>item.value.toString()
                          .replace("2", "2,8,9,10")
                          .replace("3", "3,4,5,6,11,12")).join(','):null);
                          handleSubmit();
                        }}
                  />
                  <small className="form-text text-muted">
                    Filtro por <b>Zona</b>
                  </small>
                </div>
                <div className="col-lg-3">
                  <Select 
                      // defaultValue={[business_units[0]]}
                      options={business_units} 
                      style={stylesSelect}
                      //isMulti="true"
                      onChange={(e) => {
                          //setFieldValue("business_unit_id", e?e.map(item=>item.value).join(','):null);
                          setFieldValue("business_unit_id", e?e.value:null);
                          handleSubmit();
                      }}
                  />
                  <small className="form-text text-muted">
                    Filtro por <b>Unidad de Negocio</b>
                  </small>
                </div> 
            </div>
         
            <div className="form-group row">
                <div className="col-3">
                  <Form.Check 
                          type="switch"
                          id="detailsProducts"
                          label="Con detalle de productos"
                          checked={detailsProducts}
                          onChange={(e)=>{ 
                            setFieldValue("details", e.target.checked?1:undefined)
                            setDetailsProducts(e.target.checked?true:false)
                            setDetailsArticle(false)
                            handleSubmit();
                          }}
                      />
                </div>
                <div className="col-3">
                  <Form.Check 
                          type="switch"
                          id="detailsArticle"
                          label="Con detalle de artículos"
                          checked={detailsArticle}
                          onChange={(e)=>{ 
                            setFieldValue("details", e.target.checked?2:undefined)
                            setDetailsArticle(e.target.checked?true:false)
                            setDetailsProducts(false)
                            handleSubmit();
                          }}
                      />
                </div>
                <div className="col-6 float-right">

                    <button type="button" onClick={executeReport} className="btn btn-primary float-right m-2" > 
                        <i className="fa fa-play"></i>
                        Ejecutar
                    </button>     
                    <button type="button" onClick={()=>handleExportAbbott(values)} className="btn btn-light-primary float-right m-2" > 
                        <i className="fas fa-download"></i>
                        Sellout Abbott
                    </button>          
                    { entities && entities.length>0 && 
                      <button type="button" onClick={()=>handleExportReport()} className="btn btn-light-success float-right m-2" > 
                          <i className="fa fa-file-excel"></i>
                          Exportar
                      </button>    
                    }
                    {/* { entities && entities.length>0 && 
                      <OrderReportToCSV></OrderReportToCSV>
                    } */}

                    {/* <button type="button" onClick={()=>{}} className="btn btn-light float-right m-2" > 
                        <i className="fa fa-backspace text-default"></i>
                        Limpiar Filtros
                    </button>              */}
                </div>  
            </div>

          </form>
        )}
      </Formik>
    </>
  );
}



