import React, { useState } from "react";
import * as utils from '../../../_metronic/_helpers/LocalStorageHelpers'
import { DashboardAbbottDetalleFilters } from "./DashboardAbbottDetalleFilters";
import * as dashboardCrud from './DashboardCrud';
import { useEffect } from "react";
import moment from 'moment';
import { shallowEqual, useSelector } from "react-redux";
import {MapWidget} from './Widgets/MapWidget'
import { isEqual } from "lodash";
import * as productsCrud from '../Inventory/_redux/productsCrud';

const initialFilter={
    product_id:[32, 33, 34, 99, 108, 116, 130, 133, 160, 165, 179],
    business_unit_id:null,
    dateFrom:new Date(), 
    dateTo:new Date(),
    iso_id:undefined 
}
const saveFilters= last_filter=>{
    utils.setStorage(
      'FilterAbbottDetalle',
      JSON.stringify(last_filter)
    )
  }

export function DashboardAbbottDetalle({activeTab}) {
    const [optionsProducts, setOptionsProducts] = useState([])
    const [filter, setFilter] = useState(initialFilter);
    const [entities, setEntities] = useState({});
    const [loading, setLoading] = useState(false);
    const [mostrarMapa, setMostrarMapa] = useState(false);
    const [provincia, setProvincia] = useState({});

    const { business_units } = useSelector(
        (state) => ({ business_units: state.helpers.BusinessUnits }),
        shallowEqual
    );
    

    const loadData = async()=>{
        setLoading(true);
        
        let _prod = await productsCrud.getProductsAbbott()
        _prod = _prod.data.results.map(p=>p.id).join(",")

        const params={
            business_unit_id:filter.businessUnit.value,
            order_creation_from:moment(filter.dateFrom).format("YYYYMMDD"),
            order_creation_to:moment(filter.dateTo).format("YYYYMMDD"),
            iso_id:filter.iso_id,            
            product_id:_prod || []
        }
        saveFilters(params);

        try{

            let resp = await dashboardCrud.getSalesAbbott({filter:params})
            const sales = resp.data.results;
            
            // resp = await dashboardCrud.getRanking({filter:params})
            // const ranking = resp.data.results;
            resp = await dashboardCrud.getRankingCustomers({filter:params})
            const ranking_customers = resp.data.results;

            if(sales){
                // const data=resp.data.results;
                setEntities({...entities,
                    total: sales.total || null,
                    total_tax:sales.total_tax || null,
                    shipping_total:sales.shipping_total || null,
                    count:sales.count || null,
                    // topProducts:ranking || [],
                    topCustomers:ranking_customers || []
                })
            } 
            setLoading(false);
            setMostrarMapa(true)
        }
        catch(err){
            setLoading(false);

        }     

    }
    

    useEffect(()=>{

        if(filter.businessUnit && filter.iso_id!=provincia.iso_id){
            setFilter({...filter, iso_id:provincia.iso_id})
        }
    }, [provincia])

    useEffect(()=>{
        if (!isEqual(filter, initialFilter)) {
             loadData();
        }
    }, [filter])


    useEffect(()=>{
        let _memVars = JSON.parse(utils.getStorage("FilterAbbottDetalle"));
        if(_memVars){
            setFilter({
                product_id:optionsProducts?optionsProducts.map(p=>p.value):[],
                businessUnit:business_units.filter(b=>b.value==_memVars.business_unit_id)[0],
                dateFrom:moment(_memVars.order_creation_from).toDate(), 
                dateTo:moment(_memVars.order_creation_to).toDate(), 
            })
        }
        else{
            setFilter({...filter, businessUnit:business_units.filter(b=>b.label.toUpperCase()=='SHOPDROFAR')[0]})
        }
        
    }, [])


    useEffect(()=>{
        if(activeTab=='detalle'){
            setMostrarMapa(false)
            loadData();            
        }
    }, [activeTab])

    return (
        <>
            <div className="row">
                <div className="col">
                    <DashboardAbbottDetalleFilters className="card-stretch gutter-b" filter={filter} setFilter={setFilter} optionsProducts={optionsProducts} 
                    ejecutar={loadData} />
                </div>
            </div>


            {   entities && mostrarMapa ? 
                <div className="row">
                    <MapWidget className="card-stretch gutter-b"   setProvincia={setProvincia}  entities={entities} provincia={provincia} loading={loading} />
                </div>
                :
                <div className="spinner spinner-primary mr-10"></div>
            }


        </>
    )
}













