/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { separadorMiles} from "../../Global/helpers";
import moment from 'moment'
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function KPIMontoEnviosWidget({
  className,
  baseColor = "primary",
  widgetHeight = "150px",
  filter,
  entities,
  loading
}) {
    
    const [porcentual, setPorcentual] = useState(0);
    const [baseColorState, setBaseColor] = useState('white');

    useEffect(()=>{
        if(entities && entities.cantidad_envios_principal){
            const _principal = parseFloat(entities.cantidad_envios_principal);
            const _comparacion = entities.cantidad_envios_comparacion ? parseFloat(entities.cantidad_envios_comparacion) : 0
            const _porcentual = _comparacion>0 ? ((_principal - _comparacion) / _comparacion) * 100 : 0;
            setPorcentual(parseFloat(_porcentual).toFixed(2))
            setBaseColor("white")
        }
    }, [entities])
    

  return (
    <>
        <div className={`card card-custom bg-${baseColorState} ${className}`} style={{ minHeight: widgetHeight }} >
            <div className="card-body" style={{padding:"10px"}}>
                <div className="text-left">
                    <span className="svg-icon svg-icon-3x svg-icon-primary ml-n2">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")} />
                        {loading  && <i className="fas fa-2x fa-spinner fa-spin text-white"></i> }
                    </span>

                    <table className="w-100">
                        <tbody>
                            <tr>
                                <td className={`text-inverse-${baseColorState} font-weight-bolder font-size-h1`} style={{width:"70%"}}>
                                    {separadorMiles(parseInt(entities.cantidad_envios_principal))}
                                </td>
                                <OverlayTrigger overlay={
                                    <Tooltip id="kpi-tooltip">
                                    {
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="text-left">Rango Principal:</td>
                                                    <td>
                                                        { filter?.principal_desde? moment(filter.principal_desde, 'YYYYMMDD').format('DD/MM/YYYY') : '' }
                                                        { filter?.principal_hasta? ' - ' + moment(filter.principal_hasta, 'YYYYMMDD').format('DD/MM/YYYY') : '' }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left">Rango Comparación:</td>
                                                    <td>
                                                        { filter?.comparacion_desde? moment(filter.comparacion_desde, 'YYYYMMDD').format('DD/MM/YYYY') : '' }
                                                        { filter?.comparacion_hasta? ' - ' + moment(filter.comparacion_hasta, 'YYYYMMDD').format('DD/MM/YYYY') : '' }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                    </Tooltip>
                                    } 
                                >


                                    <td className={`text-inverse-${baseColorState} font-weight-bolder font-size-h5 text-right`}> 
                                        {   porcentual != 0 ?
                                            <>
                                                <span>{porcentual}% </span>                                    
                                                <span ><i className={`fa fa-arrow-${porcentual>0?'up text-success':'down text-danger'} pl-2`} style={{fontSize:"0.93em"}}></i></span>
                                            </>
                                            :
                                            <></>
                                        }
                                    </td>
                                </OverlayTrigger>
                            </tr>
                            <tr>
                                <td className={`text-inverse-${baseColorState} font-weight-bolder font-size-h4`}>
                                    {`${entities.cantidad_envios_comparacion?separadorMiles(parseInt(entities.cantidad_envios_comparacion)):'0'}`}
                                </td>
                                <td></td>
                            </tr>
                            <tr style={{height:"50px"}}>
                                <td colSpan={2}>
                                    <span className={`text-inverse-${baseColorState} font-weight-bold text-muted font-size-h3 mt-1`} >Cantidad Total de Envíos</span>                                   
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
            </div>
        </div>
    </>
  );
}
