import React, { useState, useRef, useEffect } from "react";
import arMill from "@react-jvectormap/argentina/dist/arMill.json";
import {VectorMap} from "@react-jvectormap/core";
import { VentasWidget } from "./VentasWidget";
import { VentasEnviosWidget } from "./VentasEnviosWidget";
import { VentasPromWidget } from "./VentasPromWidget";

export function MapWidget({ className, entities, setProvincia, provincia, loading }) {
    const arRef=useRef(null);
    
    const handleMapClick = (event, code) => {

        setProvincia({iso_id:code, name:arRef.current.getRegionName(code)});

    }

    const handleRegionSelected = (event, code, isSelected, selectedRegions) => {
    }
    
    const clearRegions=()=>{
        setProvincia({iso_id:undefined, name:undefined})
        arRef.current.clearSelectedRegions()
    }


    useEffect(()=>{
        if(arRef.current && arRef.current.params){
            arRef.current.params.backgroundColor= 'transparent';
            arRef.current.params.regionsSelectable=true;
            arRef.current.params.regionsSelectableOne= true;
            arRef.current.params.strokeWidth= 1;
            
            arRef.current.params.regionStyle= {
                initial : {
                    fill : '#cdcdcd' 
                },
                hover: {
                    //'fill-opacity': 0.5,
                    fill : '#ababab'
                },
                selected: {
                    fill : '#767676'
                }
            }
        }
    }, [])


    return (
    <>
        <div className="col-lg-6" >
            <div className="card card-stretch gutter-b" style={{height:"550px"}}>
                <VectorMap 
                    map={arMill} 
                    style={{width:"100%", margin:"10px 0"}}
                    backgroundColor="white"
                    mapRef={arRef}
                    // selectedRegions= {["AR-B"]}
                    zoomButtons={false}
                    zoomOnScroll={false}

                    regionStyle={{
                        initial: {
                            fill: '#e0e0e8',
                            "fill-opacity": 1,
                            stroke: 'none',
                            "stroke-width": 0,
                            "stroke-opacity": 1
                        },
                        hover: {
                            "fill-opacity": 0.8,
                            cursor: 'pointer'
                        },
                        selected: {
                            fill: '#20c997'
                        },
                        selectedHover: {
                        }
                    }}
                    onRegionClick={handleMapClick}
                    onRegionSelected={handleRegionSelected}
                /> 
            </div>
        </div>
        <div className="col-lg-6" style={{paddingTop:"40px"}} >
            <div className="d-flex justify-content-between" style={{height:"60px"}}>
                <h1>{provincia.name || "Argentina"}</h1>
                {provincia.name && !loading &&
                    <button type="button" onClick={clearRegions} className="btn btn-light mb-5 cursor-pointer"> <i className="fa fa-search"></i>
                        Todo el país
                    </button>
                } 
            </div>
            <div >
                <VentasWidget className="gutter-b" baseColor="success" widgetHeight="120px" entities={entities}  loading={loading}/>
            </div>
            <div >
                <VentasEnviosWidget className="gutter-b" baseColor="primary" widgetHeight="120px" entities={entities} loading={loading}/>
            </div>
            <div >
                <VentasPromWidget className="card-stretch gutter-b" baseColor="warning" widgetHeight="120px" entities={entities} loading={loading}/>
            </div> 
        </div>

        </>
        
    )
}



