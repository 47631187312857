/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { separadorMiles} from "../../Global/helpers";
import moment from 'moment'
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function AbbottRecurrenciaWidget({
  className,
  baseColor = "primary",
  widgetHeight = "150px",
  filter,
  entities,
  loading
}) {
    
    const [porcentual, setPorcentual] = useState(0);
    const [baseColorState, setBaseColor] = useState('success');
    const [fechasRecurrencia, setFechasRecurrencia] = useState({})
    useEffect(()=>{
        console.log(entities)
        if(entities && entities.recurrentes){
            setBaseColor("white")
        }
        if(entities?.metadata){
            const metadata = entities.metadata;
            const aux = {
                esporadicos_desde: metadata.esporadicos?.in_dd? moment(metadata.esporadicos.in_dd).format("DD/MM/YYYY") : '',
                esporadicos_hasta: metadata.esporadicos?.in_hh? moment(metadata.esporadicos.in_hh).format("DD/MM/YYYY") : '',
                
                inactivos_not_desde: metadata.inactivos?.not_in_dd? moment(metadata.inactivos.not_in_dd).format("DD/MM/YYYY") : '',
                inactivos_not_hasta: metadata.inactivos?.not_in_hh? moment(metadata.inactivos.not_in_hh).format("DD/MM/YYYY") : '',
                inactivos_desde: metadata.inactivos?.in_dd? moment(metadata.inactivos.in_dd).format("DD/MM/YYYY") : '',
                inactivos_hasta: metadata.inactivos?.in_hh? moment(metadata.inactivos.in_hh).format("DD/MM/YYYY") : '',
                
                perdidos_not_desde: metadata.perdidos?.not_in_dd? moment(metadata.perdidos.not_in_dd).format("DD/MM/YYYY") : '',
                perdidos_not_hasta: metadata.perdidos?.not_in_hh? moment(metadata.perdidos.not_in_hh).format("DD/MM/YYYY") : '',
                perdidos_desde: metadata.perdidos?.in_dd? moment(metadata.perdidos.in_dd).format("DD/MM/YYYY") : '',
                perdidos_hasta: metadata.perdidos?.in_hh? moment(metadata.perdidos.in_hh).format("DD/MM/YYYY") : '',
                
                recuperados_not_desde: metadata.recuperados?.not_in_dd? moment(metadata.recuperados.not_in_dd).format("DD/MM/YYYY") : '',
                recuperados_not_hasta: metadata.recuperados?.not_in_hh? moment(metadata.recuperados.not_in_hh).format("DD/MM/YYYY") : '',
                recuperados_desde: metadata.recuperados?.in_dd? moment(metadata.recuperados.in_dd).format("DD/MM/YYYY") : '',
                recuperados_hasta: metadata.recuperados?.in_hh? moment(metadata.recuperados.in_hh).format("DD/MM/YYYY") : '',
                recuperados_desde2: metadata.recuperados?.in_2_dd? moment(metadata.recuperados.in_2_dd).format("DD/MM/YYYY") : '',
                recuperados_hasta2: metadata.recuperados?.in_2_hh? moment(metadata.recuperados.in_2_hh).format("DD/MM/YYYY") : '',

                nuevos_desde: metadata.nuevos?.in_dd? moment(metadata.nuevos.in_dd).format("DD/MM/YYYY") : '',
                nuevos_hasta: metadata.nuevos?.in_hh? moment(metadata.nuevos.in_hh).format("DD/MM/YYYY") : '',

                recurrentes_desde: metadata.recurrentes?.in_dd? moment(metadata.recurrentes.in_dd).format("DD/MM/YYYY") : '',
                recurrentes_hasta: metadata.recurrentes?.in_hh? moment(metadata.recurrentes.in_hh).format("DD/MM/YYYY") : '',
            }
            setFechasRecurrencia(aux)

        }

    }, [entities])
    
    // recurrentes
    // perdidos
    // recuperados
    // inactivos
  return (
    <>
        <div className="row">
            <div className="col">
                <div className={`card card-custom bg-${baseColorState} ${className}`} style={{ minHeight: widgetHeight }} >
                    <div className="card-body" style={{padding:"10px"}}>
                        <div className="text-left">
                            <span className="svg-icon svg-icon-3x svg-icon-success ml-n2">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Contact1.svg")} />
                                {loading  && <i className="fas fa-2x fa-spinner fa-spin text-white"></i> }
                            </span>
                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <td className={`text-inverse-${baseColorState} font-weight-bolder font-size-h1`} style={{width:"70%"}}>{entities?.recurrentes}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className={`text-inverse-${baseColorState} font-weight-bolder font-size-h3`}>{entities?.recurrentes_producto}</td>
                                        <td></td>
                                    </tr>
                                    <tr style={{height:"80px"}}>
                                        <OverlayTrigger overlay={
                                                <Tooltip>
                                                <p className="p-0 m-0">Ha comprado 10 sensores o más entre </p>
                                                <p className="p-0 m-0">el {fechasRecurrencia.recurrentes_desde} y el {fechasRecurrencia.recurrentes_hasta} </p>
                                                </Tooltip>
                                            } 
                                        >
                                            <td colSpan={2}>
                                                <span className={`text-inverse-${baseColorState} font-weight-bold text-muted font-size-h3 mt-1`} >Recurrentes</span>
                                            </td>
                                        </OverlayTrigger>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className={`card card-custom bg-${baseColorState} ${className}`} style={{ minHeight: widgetHeight }} >
                    <div className="card-body" style={{padding:"10px"}}>
                        <div className="text-left">
                            <span className="svg-icon svg-icon-3x svg-icon-warning ml-n2">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Contact1.svg")} />
                                {loading  && <i className="fas fa-2x fa-spinner fa-spin text-white"></i> }
                            </span>

                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <td className={`text-inverse-${baseColorState} font-weight-bolder font-size-h1`} style={{width:"70%"}}>{entities.perdidos}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className={`text-inverse-${baseColorState} font-weight-bolder font-size-h3`}>{entities.perdidos_producto}</td>
                                        <td></td>
                                    </tr>
                                    <tr style={{height:"80px"}}>
                                        <OverlayTrigger overlay={
                                            <Tooltip>                                                
                                                <p className="p-0 m-0">Compró entre el {fechasRecurrencia.perdidos_desde} y el {fechasRecurrencia.perdidos_hasta} y no compró entre 
                                                    el {fechasRecurrencia.perdidos_not_desde} y el {fechasRecurrencia.perdidos_not_hasta} </p>
                                            </Tooltip>
                                            } 
                                        >
                                        <td colSpan={2}>
                                            <span className={`text-inverse-${baseColorState} font-weight-bold text-muted font-size-h3 mt-1`} >Perdidos</span>                                   
                                        </td>
                                        </OverlayTrigger>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className={`card card-custom bg-${baseColorState} ${className}`} style={{ minHeight: widgetHeight }} >
                    <div className="card-body" style={{padding:"10px"}}>
                        <div className="text-left">
                            <span className="svg-icon svg-icon-3x svg-icon-primary ml-n2">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Contact1.svg")} />
                                {loading  && <i className="fas fa-2x fa-spinner fa-spin text-white"></i> }
                            </span>

                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <td className={`text-inverse-${baseColorState} font-weight-bolder font-size-h1`} style={{width:"70%"}}>{entities.recuperados}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className={`text-inverse-${baseColorState} font-weight-bolder font-size-h3`}>{entities.recuperados_producto}</td>
                                        <td></td>
                                    </tr>
                                    <tr style={{height:"80px"}}>
                                        <OverlayTrigger overlay={
                                                <Tooltip>
                                                <p>Compró el {fechasRecurrencia.recuperados_desde2}, luego no compró desde el {fechasRecurrencia.recuperados_not_desde} hasta el {fechasRecurrencia.recuperados_not_hasta}.</p>
                                                <p>Volvió a comprar entre el {fechasRecurrencia.recuperados_desde} y el {fechasRecurrencia.recuperados_hasta}</p>
                                                </Tooltip>
                                            } 
                                        >
                                        <td colSpan={2}>
                                            <span className={`text-inverse-${baseColorState} font-weight-bold text-muted font-size-h3 mt-1`} >Recuperados</span>                                   
                                        </td>
                                        </OverlayTrigger>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-4">
                <div className={`card card-custom bg-${baseColorState} ${className}`} style={{ minHeight: widgetHeight }} >
                    <div className="card-body" style={{padding:"10px"}}>
                        <div className="text-left">
                            <span className="svg-icon svg-icon-3x svg-icon-primary ml-n2">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Contact1.svg")} />
                                {loading  && <i className="fas fa-2x fa-spinner fa-spin text-white"></i> }
                            </span>

                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <td className={`text-inverse-${baseColorState} font-weight-bolder font-size-h1`} style={{width:"70%"}}>{entities.esporadicos}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className={`text-inverse-${baseColorState} font-weight-bolder font-size-h3`}>{entities.esporadicos_producto}</td>
                                        <td></td>
                                    </tr>
                                    <tr style={{height:"80px"}}>
                                        <OverlayTrigger overlay={
                                                <Tooltip>
                                                    <p>Compró entre el {fechasRecurrencia.esporadicos_desde} y el {fechasRecurrencia.esporadicos_hasta}</p>   
                                                </Tooltip>
                                            } 
                                        >
                                            <td colSpan={2}>
                                                <span className={`text-inverse-${baseColorState} font-weight-bold text-muted font-size-h3 mt-1`} >Esporádicos</span>
                                            </td>
                                        </OverlayTrigger>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>            
            </div>
            <div className="col-4">
                <div className={`card card-custom bg-${baseColorState} ${className}`} style={{ minHeight: widgetHeight }} >
                    <div className="card-body" style={{padding:"10px"}}>
                        <div className="text-left">
                            <span className="svg-icon svg-icon-3x svg-icon-danger ml-n2">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Contact1.svg")} />
                                {loading  && <i className="fas fa-2x fa-spinner fa-spin text-white"></i> }
                            </span>

                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <td className={`text-inverse-${baseColorState} font-weight-bolder font-size-h1`} style={{width:"70%"}}>{entities.inactivos}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className={`text-inverse-${baseColorState} font-weight-bolder font-size-h3`}>{entities.inactivos_producto}</td>
                                        <td></td>
                                    </tr>
                                    <tr style={{height:"80px"}}>
                                        <OverlayTrigger overlay={
                                                <Tooltip>
                                                    <p>Compró entre {fechasRecurrencia.inactivos_desde} y el {fechasRecurrencia.inactivos_hasta}.</p>
                                                    <p>No compra entre el {fechasRecurrencia.inactivos_not_desde} y el {fechasRecurrencia.inactivos_not_hasta}</p>
                                                </Tooltip>
                                            } 
                                        >
                                            <td colSpan={2}>
                                                <span className={`text-inverse-${baseColorState} font-weight-bold text-muted font-size-h3 mt-1`} >Inactivos</span>
                                            </td>
                                        </OverlayTrigger>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>            
            </div>
        </div>

    </>



  );
}
