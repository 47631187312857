import React, { useState } from "react";
import * as utils from '../../../_metronic/_helpers/LocalStorageHelpers'
import { DashboardAbbottPrincipalFilters } from "./DashboardAbbottPrincipalFilters";
import { KPILectoresWidget } from "./Widgets/KPILectoresWidget";
import { KPIOrdenesWidget } from "./Widgets/KPIOrdenesWidget";
import { KPISensoresWidget } from "./Widgets/KPISensoresWidget";
import * as dashboardCrud from './DashboardCrud';
import * as productsCrud from '../Inventory/_redux/productsCrud';
import { useEffect } from "react";
import moment from 'moment';
import { shallowEqual, useSelector } from "react-redux";
import { KPIIvaWidget } from "./Widgets/KPIIvaWidget";
import { KPIMontoSubtotalWidget } from "./Widgets/KPIMontoSubtotalWidget";
import { KPIMontoTotalWidget } from "./Widgets/KPIMontoTotalWidget";
import { KPIMontoEnviosWidget } from "./Widgets/KPIMontoEnviosWidget";
import { KPIArticulosPorTicketWidget } from "./Widgets/KPIArticulosPorTicketWidget";
import { KPITicketPromedioWidget } from "./Widgets/KPITicketPromedioWidget";
import { KPIClientesTotalesWidget } from "./Widgets/KPIClientesTotalesWidget";
import { KPIClientesNuevosWidget } from "./Widgets/KPIClientesNuevosWidget";
import { CircularProgress } from "@material-ui/core";


const initialFilter={
    product_id:null,
    business_unit_id:null,
    principal_desde:null, 
    principal_hasta:null, 
    comparacion_desde:null, 
    comparacion_hasta:null
}
const saveFilters= last_filter=>{
    utils.setStorage(
      'FilterAbbottPrincipal',
      JSON.stringify(last_filter)
    )
  }

export function DashboardAbbottPrincipal({activeTab}) {
    const [optionsProducts, setOptionsProducts] = useState([])
    const [filter, setFilter] = useState(initialFilter);
    const [entities, setEntities] = useState({});
    const [loading, setLoading] = useState(false);
    
    const { business_units } = useSelector(
        (state) => ({ business_units: state.helpers.BusinessUnits }),
        shallowEqual
      );
    
    const fetchProducts = async()=>{
        const resp = await productsCrud.getProductsAbbott()
        setOptionsProducts(resp.data.results.map(p=>({value:p.id, label:p.name})))
    }

    const loadData = async()=>{
        setLoading(true);
        const params={
            business_unit_id:filter.business_unit_id,
            product_id:filter.product_id,
            principal_desde:filter.principal_desde,
            principal_hasta:filter.principal_hasta,
            comparacion_desde:filter.comparacion_desde,
            comparacion_hasta:filter.comparacion_hasta
        }
        saveFilters(params);

        if(!params.product_id){
            params.product_id = optionsProducts.map(p=>p.value)
        }

        try{
            let resp = await dashboardCrud.getDashboardPrincipal(params)
            const sales = resp.data.results;
            resp = await dashboardCrud.getDashboardClientes(params)
            const clientes =  resp.data.results;
            // resp = await dashboardCrud.getDashboardRecurrencia(params)
            // const recurrencia =  resp.data.results;
            // const metadata =  resp.data.metadata;

console.log({...sales, ...clientes, hayDatos:true})
            if(sales && sales.total_principal ){
                setEntities({...sales, ...clientes, hayDatos:true})
                console.log(sales)
            } 
            else{
                setEntities({hayDatos:false})
            }
            setLoading(false);

        }
        catch(err){
            setLoading(false);

        }      

    }
    


    useEffect(()=>{
        if(filter?.principal_desde && filter?.principal_hasta)
            loadData();
    }, [filter])


    useEffect(()=>{
        
        let _memVars = JSON.parse(utils.getStorage("FilterAbbottPrincipal"));
        if(_memVars){
            setFilter({
                product_id: _memVars.product_id,
                business_unit_id:business_units.filter(b=>b.value==_memVars.business_unit_id).map(v=>v.value),
                principal_desde:moment(_memVars.principal_desde)?.format("YYYYMMDD"), 
                principal_hasta:moment(_memVars.principal_hasta)?.format("YYYYMMDD"), 
                comparacion_desde:moment(_memVars.comparacion_desde)?.format("YYYYMMDD"), 
                comparacion_hasta:moment(_memVars.comparacion_hasta)?.format("YYYYMMDD")
            })
        }
        else{
            setFilter({...filter, businessUnit:business_units.filter(b=>b.label.toUpperCase()=='SHOPDROFAR')[0]})
        }
        
    }, [])

    useEffect(()=>{
        fetchProducts()
        if(activeTab=='principal'){

        }
        
    }, [activeTab])



    return (
        <>
            <div className="row">
                <div className="col">
                    <DashboardAbbottPrincipalFilters className="card-stretch gutter-b" filter={filter} 
                        setFilter={setFilter} optionsProducts={optionsProducts} setLoading={setLoading} />
                </div>
            </div>

            { loading 
                ?
                    <div className="row">
                        <div  className="d-flex justify-content-center align-items-center" style={{ width: '100vw', height:"100px"}}>
                            {/* <div  className="spinner spinner-primary"></div> */}
                            <CircularProgress className="splash-screen-spinner" color="primary" />
                        </div>                        
                    </div>
                :
                    ( entities?.hayDatos?
                        <>
                            <div className="row">
                                <div className="col">
                                    <h3>KPI Ventas</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <KPIOrdenesWidget className="gutter-b"  widgetHeight="180px" entities={entities} filter={filter} />
                                </div>
                                <div className="col">
                                    <KPILectoresWidget className="gutter-b"  widgetHeight="180px" entities={entities} filter={filter} />
                                </div>
                                <div className="col">
                                    <KPISensoresWidget className="gutter-b"  widgetHeight="180px" entities={entities} filter={filter} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <KPIMontoEnviosWidget className="gutter-b"  widgetHeight="180px" entities={entities} filter={filter} />
                                </div>
                                <div className="col">
                                    <KPIMontoTotalWidget className="gutter-b"  widgetHeight="180px" entities={entities} filter={filter} />
                                </div>  
                            </div>

                            <div className="row">
                                <div className="col">
                                    <KPITicketPromedioWidget className="gutter-b"  widgetHeight="180px" entities={entities} filter={filter} />
                                </div>   
                                <div className="col">
                                    <KPIArticulosPorTicketWidget className="gutter-b"  widgetHeight="180px" entities={entities} filter={filter} />
                                </div> 
                            </div>





                            <div className="row mt-4">
                                <div className="col">
                                    <h3>KPI Clientes</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <KPIClientesTotalesWidget className="gutter-b"  widgetHeight="180px" entities={entities} filter={filter} />
                                </div>  
                                <div className="col">
                                    <KPIClientesNuevosWidget className="gutter-b"  widgetHeight="180px" entities={entities} filter={filter} />
                                </div> 
                            </div>

                            {/* <div className="row mt-4">
                                <div className="col">
                                    <h3>Recurrencia</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                <AbbottRecurrenciaWidget className="gutter-b"  widgetHeight="180px" entities={entities} filter={filter} />
                                </div> 

                            </div> */}

                        </>
                    :
                            <h3>No hay datos disponibles</h3>
                        )
                        
                    }

        </>
    )
}