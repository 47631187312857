import Noty from 'noty';  

export function successMsg(texto) {
    new Noty({  
        text: texto,
        layout: "top",
        timeout:1000,
        progressBar:false,
        closeWith:['click', 'button'],
        theme: "bootstrap-v4",
        type: "success"
      }).show();
}

export function errorMsg(texto) {
    new Noty({  
        text: texto,
        layout: "top",
        timeout:1500,
        progressBar:false,
        closeWith:['click', 'button'],
        theme: "bootstrap-v4",
        type: "error"
      }).show();
}

export function separadorMiles (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
} 

export const decimalFormatter = new Intl.NumberFormat('es-AR', {
  style: 'decimal'
})

