/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  );

  const { user } = authState;

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>

      { (user.role.name=='ADMIN' || user.role.name=='LOGISTICA' || user.role.name=='FARMACIA' || user.role.name=='ADMINISTRACION') &&
        <>
          <li className={`menu-item menu-item-submenu ${getMenuItemActive( "/logistica", true )}`} aria-haspopup="true" data-menu-toggle="hover" >

            <NavLink className="menu-link menu-toggle" to="/logistica">
              <span className="menu-text"><i className="fas fa-shopping-cart mr-3"></i>Logística</span>
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">

                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Logística</span>
                  </span>
                </li>

                <li className={`menu-item ${getMenuItemActive( "/logistica/ordenes" )}`} aria-haspopup="true" >
                  <NavLink className="menu-link" to="/logistica/ordenes">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Ordenes</span>
                  </NavLink>
                </li>


                { (user.role.name=='ADMIN' || user.role.name=='LOGISTICA')  &&  
                    <>
                      <li className={`menu-item ${getMenuItemActive( "/logistica/olas")}`} aria-haspopup="true" >
                        <NavLink className="menu-link" to="/logistica/olas">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Olas</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item ${getMenuItemActive( "/logistica/despachos")}`} aria-haspopup="true" >
                        <NavLink className="menu-link" to="/logistica/despachos">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Despachos</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item ${getMenuItemActive( "/logistica/entregas")}`} aria-haspopup="true" >
                        <NavLink className="menu-link" to="/logistica/entregas">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Entregas/Devoluciones</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item ${getMenuItemActive( "/logistica/movimientos")}`} aria-haspopup="true" >
                        <NavLink className="menu-link" to="/logistica/movimientos">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Movimientos Depósitos</span>
                        </NavLink>
                      </li>
                    </>
                }

              </ul>
            </div>
          </li>
        </>}

        { (user.role.name=='ADMIN' || user.role.name=='FARMACIA')  && 
            <li className={`menu-item ${getMenuItemActive("/farmacia", false)}`} aria-haspopup="true" >
              <NavLink className="menu-link  menu-toggle" to="/farmacia">
                <span className="menu-text"><i className="fas fa-clinic-medical mr-3"></i>Farmacia</span>
              </NavLink>
              
              <div className="menu-submenu">
                <i className="menu-arrow" />
                <ul className="menu-subnav">

                  <li className="menu-item menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                      <span className="menu-text">Farmacia</span>
                    </span>
                  </li>

                  <li className={`menu-item ${getMenuItemActive( "/farmacia/entregas" )}`} aria-haspopup="true" >
                    <NavLink className="menu-link" to="/farmacia/entregas">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Entregas</span>
                    </NavLink>
                  </li>
                  <li className={`menu-item ${getMenuItemActive( "/farmacia/cierre" )}`} aria-haspopup="true" >
                    <NavLink className="menu-link" to="/farmacia/cierre">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Cierre de Caja</span>
                    </NavLink>
                  </li>

                </ul>
              </div>
            </li>
        }
        
        { (user.role.name=='ADMIN' || user.role.name=='LOGISTICA' || user.role.name=='FARMACIA' || user.role.name=='ADMINISTRACION') &&
          <>
            <li className={`menu-item ${getMenuItemActive("/clientes", false)}`} aria-haspopup="true" >
              <NavLink className="menu-link" to="/clientes">
                <span className="menu-text"><i className="fas fa-user-friends mr-3"></i>Clientes</span>
              </NavLink>
            </li>
          </>
        }

        { (user.role.name=='ADMIN' || user.role.name=='INVENTARIO' || user.role.name=='GERENCIA' || user.role.name=='ADMINISTRACION')  && 
            <li className={`menu-item ${getMenuItemActive("/inventario", false)}`} aria-haspopup="true" >
              <NavLink className="menu-link menu-toggle" to="/inventario">
                <span className="menu-text"><i className="fas fa-warehouse mr-3"></i>Inventario</span>
              </NavLink>
              
              <div className="menu-submenu">
                <i className="menu-arrow" />
                <ul className="menu-subnav">

                  <li className="menu-item menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                      <span className="menu-text">Inventario</span>
                    </span>
                  </li>

                  <li className={`menu-item ${getMenuItemActive( "/inventario/productos" )}`} aria-haspopup="true" >
                    <NavLink className="menu-link" to="/inventario/productos">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Productos</span>
                    </NavLink>
                  </li>
                  <li className={`menu-item ${getMenuItemActive( "/inventario/alfabeta" )}`} aria-haspopup="true" >
                    <NavLink className="menu-link" to="/inventario/alfabeta">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Manual Farmacéutico</span>
                    </NavLink>
                  </li>
                  <li className={`menu-item ${getMenuItemActive( "/inventario/laboratorios" )}`} aria-haspopup="true" >
                    <NavLink className="menu-link" to="/inventario/laboratorios">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Laboratorios</span>
                    </NavLink>
                  </li>

                </ul>
              </div>
            </li>
        }

        { (user.role.name=='ADMIN' || user.role.name=='INVENTARIO' || user.role.name=='GERENCIA'  || user.role.name=='ADMINISTRACION' || 
            user.role.name=='LOGISTICA' || user.role.name=='FARMACIA')  && 
            <li className={`menu-item ${getMenuItemActive("/reportes", false)}`} aria-haspopup="true" >
              <NavLink className="menu-link" to="/reportes">
                <span className="menu-text"><i className="fas fa-chart-pie mr-3"></i>Reportes</span>
              </NavLink>
            </li>
        }

        { user.role.name=='ADMIN'  && 
            <li className={`menu-item ${getMenuItemActive("/usuarios", false)}`} aria-haspopup="true" >
              <NavLink className="menu-link" to="/usuarios">
                <span className="menu-text"><i className="fas fa-user-lock mr-3"></i>Usuarios</span>
              </NavLink>
            </li>
        }

        { (user.role.name=='ADMIN' || user.role.name=='ADMINISTRACION')  && 
            <li className={`menu-item ${getMenuItemActive("/dashboard", false)}`} aria-haspopup="true" >
              <NavLink className="menu-link" to="/dashboard">
                <span className="menu-text"><i className="fas fa-chart-bar mr-3"></i>Tablero</span>
              </NavLink>
            </li>
        }

        { (user.role.name=='ADMIN' || user.role.name=='ABBOTT')  && 
            <li className={`menu-item ${getMenuItemActive("/dashboardV2", false)}`} aria-haspopup="true" >
              <NavLink className="menu-link" to="/dashboardV2">
                <span className="menu-text"><i className="fas fa-chart-bar mr-3"></i>Tablero Abbott</span>
              </NavLink>
            </li>
        }

      </ul>


    </>
  );
}
