import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { shallowEqual, useSelector } from "react-redux";
import Select from 'react-select';
import moment from 'moment';

export function DashboardAbbottDetalleFilters({className, filter, setFilter, ejecutar}) {

    const { business_units } = useSelector(
        (state) => ({ business_units: state.helpers.BusinessUnits }),
        shallowEqual
      );

      
    // const ejecutar = ()=>{
    //     if(filter.dateFrom && filter.dateTo){
    //         setFilter({
    //             ...filter, 
    //             dateFrom : moment(filter.dateFrom).format("YYYYMMDD"), 
    //             dateTo : moment(filter.dateTo).format("YYYYMMDD"), 
    //         })
    //     }
    // }

    return (
        <>
            <div className={`card card-custom ${className}`}>
                <div className="card-body pt-2 pb-0">
                    <div className="row mt-5 pb-5">
                        <div className="col">
                            <ReactDatePicker
                                autoComplete='off'
                                className="form-control"
                                style={{ width: "100%" }}
                                name="dateFrom"
                                selected={filter.dateFrom}
                                maxDate={filter.dateTo?(filter.dateTo>new Date()?new Date():filter.dateTo):new Date()}
                                dateFormat="dd/MM/yyyy"
                                onChange={date=>{ setFilter({...filter, dateFrom:date}) }}

                            />
                            <small className="form-text text-muted">
                            Fecha <b>desde</b>
                            </small>
                        </div>
                        <div className="col">
                            <ReactDatePicker
                                autoComplete='off'
                                className="form-control"
                                style={{ width: "100%" }}
                                name="dateTo"
                                selected={filter.dateTo}
                                minDate={filter.dateFrom}
                                dateFormat="dd/MM/yyyy"
                                onChange={date=>{ setFilter({...filter, dateTo:date}) }}

                            />
                            <small className="form-text text-muted">
                            Fecha <b>hasta</b>
                            </small>
                        </div>
                        <div className="col">
                            <Select 
                                options={business_units} 
                                value = {filter.businessUnit}
                                onChange={value=>{setFilter({...filter, businessUnit:value})}}
                                isDisabled={true}
                            />
                            <small className="form-text text-muted">
                            Filtro por <b>Unidad de Negocio</b>
                            </small>
                        </div> 
                        <div className="col">
                            
                        </div>
                        {/* <div className="col-3 text-right">
                            <button type='button' className="btn btn-primary"  onClick={()=>ejecutar()}>Ejecutar</button>
                        </div> */}
                    </div>            
                </div>      
            </div>
        </>
    )
}