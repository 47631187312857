import {createSlice} from "@reduxjs/toolkit";

const initialHelpersState = {
  listLoading: false,
  actionsLoading: false,
  ShippingCarriers: [],
  ShippingCarriersOptions: [],
  BusinessUnits : [{value:1, label:'SHOPDROFAR'},{value:2, label:'ETHICAL NUTRITION'},{value:3, label:'MI_TEST'}],
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

const getShippingCarriersOptions = (entities)=>{
    let transportes=[];
    for(let carrier of entities){
      for(let service of carrier.services){
        transportes.push({
          label:service.shipping_carrier_name + ' - ' + service.name + (service.courier_name? ' - ' + service.courier_name:''),
          value:service.id,
          shipping_type_id:service.shipping_type_id,
          carrier_id:carrier.id
        })
      }
    }
    return transportes;
}

export const helpersSlice = createSlice({
  name: "helpers",
  initialState: initialHelpersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getOrderById
    shippingCarriersFetched: (state, action) => {
      state.actionsLoading = false;
      state.ShippingCarriers = action.payload.entities;
      state.ShippingCarriersOptions = getShippingCarriersOptions(action.payload.entities)
      state.error = null;
    },

    
  }
});
